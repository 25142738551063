.ant-drawer-header {
  border-bottom: 1px solid #c0c0c0;
}

.ant-drawer-title {
  color: #000;
  font-size: 20px;
  font-weight: 900;
}

.ant-drawer-body {
  padding: 12px;
}