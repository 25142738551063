.container {
  display: grid;
  gap: 16px;
  grid-template-areas:
    "division region"
    "channel cell"
    "amcode ."
}

@media (min-width: 1200px) {
  .container {
    grid-template-areas:
      "division region amcode"
      "channel cell ."
      "channel cell ."
  }
}

